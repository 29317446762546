import { Show, For } from 'solid-js';
import { Picture, TextLink } from '@troon/ui';
import { Dynamic } from 'solid-js/web';
import { IconFlag } from '@troon/icons/flag';
import { IconNavigation } from '@troon/icons/navigation';
import { IconPhone } from '@troon/icons/phone';
import { IconGlobe } from '@troon/icons/globe';
import { getConfigValue } from '../../modules/config';
import { gql } from '../../graphql';
import { createFragment } from '../../graphql/create-fragment';
import type { IconComponent } from '@troon/icons';
import type { FragmentType } from '../../graphql';
import type { ParentProps } from 'solid-js';

type Props = {
	facility: FragmentType<typeof FacilityLocationFragment>;
};

export function FacilityLocation(props: Props) {
	const facility = createFragment(FacilityLocationFragment, props, 'facility');
	return (
		<div class="flex flex-col gap-6 @container/loc">
			<Picture
				src={`https://${getConfigValue('MAP_HOST')}/course/${facility.slug}/map.png`}
				alt={`Street map of ${facility.name} and its local surroundings.`}
				width={800}
				height={400}
				sizes="(min-width: 1024px) 20vw, 95vw"
				class="rounded"
				loading="lazy"
			/>

			<h2 class="sr-only">Course details</h2>
			<div class="flex flex-col divide-y divide-solid divide-neutral-500 @5xl/loc:flex-row @5xl/loc:divide-x @5xl/loc:divide-y-0">
				<Show when={(facility.courses?.length ?? 0) > 1}>
					<Details title="Courses" icon={IconFlag}>
						<ul>
							<For each={facility.courses}>{(course) => <li>{course.name}</li>}</For>
						</ul>
					</Details>
				</Show>

				<Show when={facility.metadata?.address}>
					{(address) => (
						<Details title="Address" icon={IconNavigation}>
							<address
								class="font-medium not-italic text-neutral-800"
								aria-label={`Address for ${facility.name}`}
								itemprop="address"
								itemscope
								itemtype="https://schema.org/PostalAddress"
							>
								<span itemprop="streetAddress">{address().street}</span>
								<br />
								<span itemprop="addressLocality">{address().city}</span>,{' '}
								<span itemprop="addressRegion">{address().state}</span>{' '}
								<span itemprop="postalCode">{address().postalCode}</span>
							</address>
						</Details>
					)}
				</Show>

				<Show when={facility.metadata?.phone}>
					{(phone) => (
						<Details title="Phone" icon={IconPhone}>
							<p>
								<TextLink href={`tel:${phone()}`}>{phone()}</TextLink>
							</p>
						</Details>
					)}
				</Show>

				<Show when={facility.metadata!.website}>
					{(website) => (
						<Details title="Website" icon={IconGlobe}>
							<p>
								<TextLink href={website()} target="_blank">
									{new URL(website()).hostname}
								</TextLink>
							</p>
						</Details>
					)}
				</Show>
			</div>
		</div>
	);
}

type DetailsProps = ParentProps<{
	title: string;
	icon: IconComponent;
}>;

function Details(props: DetailsProps) {
	return (
		<div class="flex flex-row items-center justify-between gap-2 py-4 @5xl/loc:basis-1/3 @5xl/loc:flex-col-reverse @5xl/loc:items-start @5xl/loc:justify-end @5xl/loc:px-6 @5xl/loc:first:ps-0 @5xl/loc:last:pe-0">
			<div>
				<h3 class="font-semibold">{props.title}</h3>
				{props.children}
			</div>
			<span class="flex size-10 items-center justify-center rounded bg-brand-100 p-1 text-xl text-brand">
				<Dynamic component={props.icon} />
			</span>
		</div>
	);
}

const FacilityLocationFragment = gql(`fragment FacilityLocation on Facility {
	slug
  name
  courses {
    name
  }
  metadata {
    address {
      street
      street2
      city
      state
      country
      postalCode
    }
    phone
    website
  }
}`);
